var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('r-page',{scopedSlots:_vm._u([{key:"page-header",fn:function(){return [_c('h2',{staticClass:"page-title"},[_vm._v("General Setting")])]},proxy:true},{key:"page-content",fn:function(){return [_c('div',{attrs:{"id":"general-setting"}},[(_vm.currentUser)?_c('div',{staticClass:"columns is-multiline is-mobile"},[_c('div',{staticClass:"column is-4"},[(_vm.currentUser.isAdmin)?_c('div',{staticClass:"columns is-multiline is-mobile"},[_c('div',{staticClass:"column is-12 is-mobile pb-0"},[_c('p',[_vm._v("Maximum Change Schedule (days per month)")]),(!_vm.isLoadingMaxChangeSchedule)?_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"is-label-14-px-normal",attrs:{"multipart":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.saveChangeSchedule)}}},[_c('div',{staticClass:"columns is-multiline is-mobile"},[_c('div',{staticClass:"column is-10"},[_c('ValidationProvider',{attrs:{"name":"maximum change schedule","rules":"required|numeric"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-input',{attrs:{"placeholder":"e.g. 12"},model:{value:(_vm.maxChangeSchedule),callback:function ($$v) {_vm.maxChangeSchedule=$$v},expression:"maxChangeSchedule"}}),_c('p',{staticClass:"has-text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"column is-2"},[_c('b-button',{staticClass:"is-primary",attrs:{"native-type":"submit","type":"submit","loading":_vm.isSavingMaxChangeSchedule}},[_vm._v(" Save ")])],1)])])]}}],null,false,1068156140)}):_c('b-skeleton',{attrs:{"animated":""}})],1)]):_vm._e(),(_vm.currentUser.isAdmin)?_c('div',{staticClass:"columns is-multiline is-mobile"},[_c('div',{staticClass:"column is-12 is-mobile pb-0"},[_c('p',[_vm._v("Maximum Delay Limit")]),(!_vm.isLoadingMaxDelayTime)?_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"is-label-14-px-normal",attrs:{"multipart":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.saveMaxDelayTime)}}},[_c('div',{staticClass:"columns is-multiline is-mobile"},[_c('div',{staticClass:"column is-10"},[_c('ValidationProvider',{attrs:{"name":"maximum delay limit","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-timepicker',{attrs:{"placeholder":"Click to edit...","hour-format":"24","enable-seconds":"","editable":""},model:{value:(_vm.maxDelayTime),callback:function ($$v) {_vm.maxDelayTime=$$v},expression:"maxDelayTime"}}),_c('p',{staticClass:"has-text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"column is-2"},[_c('b-button',{staticClass:"is-primary",attrs:{"native-type":"submit","type":"submit","loading":_vm.isSavingMaxDelayTime}},[_vm._v(" Save ")])],1)])])]}}],null,false,3711953067)}):_c('b-skeleton',{attrs:{"animated":""}})],1)]):_vm._e(),(_vm.currentUser.isAdmin)?_c('div',{staticClass:"columns is-multiline is-mobile"},[_c('div',{staticClass:"column is-12 is-mobile pb-0"},[_c('p',[_vm._v("Face Recognition Mandatory")]),(!_vm.isLoadingFaceRegMandatory)?_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"is-label-14-px-normal",attrs:{"multipart":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.saveFaceRegMandatory)}}},[_c('div',{staticClass:"columns is-multiline is-mobile"},[_c('div',{staticClass:"column is-10"},[_c('ValidationProvider',{attrs:{"name":"face recognition mandatory","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-switch',{attrs:{"type":"is-success"},model:{value:(_vm.isFaceRegMandatory),callback:function ($$v) {_vm.isFaceRegMandatory=$$v},expression:"isFaceRegMandatory"}}),_c('p',{staticClass:"has-text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1),_c('div',{staticClass:"column is-2"},[_c('b-button',{staticClass:"is-primary",attrs:{"native-type":"submit","type":"submit","loading":_vm.isSavingFaceRegMandatory}},[_vm._v(" Save ")])],1)])])]}}],null,false,389342868)}):_c('b-skeleton',{attrs:{"animated":""}})],1)]):_vm._e(),_c('div',{staticClass:"columns is-multiline is-mobile"},[_c('div',{staticClass:"column is-12 is-mobile pb-0"},[_c('p',[_vm._v("Set Face Recognition")]),(!_vm.isLoadingSetImageFaceReg)?_c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
return [_c('form',{staticClass:"is-label-14-px-normal",attrs:{"multipart":""},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.saveFaceRegMandatory)}}},[_c('div',{staticClass:"columns is-multiline is-mobile"},[_c('div',{staticClass:"column is-10"},[_c('p',[_vm._v("Please take a picture to recognize your identity")])]),_c('div',{staticClass:"column is-2"},[_c('b-button',{staticClass:"is-primary",attrs:{"type":"primary"},nativeOn:{"click":function($event){return _vm.openModal($event)}}},[_vm._v(" "+_vm._s(_vm.imageFaceReg.url ? 'Re-capture' : 'Start')+" ")])],1)])])]}}],null,false,2813331186)}):_c('b-skeleton',{attrs:{"animated":""}})],1)])])]):_vm._e(),_c('b-modal',{attrs:{"active":_vm.isModalFaceCamOpen,"trap-focus":"","has-modal-card":""},on:{"close":_vm.closeModal},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('FaceCam',{attrs:{"action":"takePicture","submit-loading":_vm.isSavingFaceReg,"is-capture-finished":_vm.isCaptureFinished,"is-close-face-cam":_vm.isCloseFaceCam,"show-button-capture-finished":""},on:{"close":props.close,"submit":_vm.saveImageFaceReg}})]}}])})],1)]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }