<template>
  <r-page>
    <template #page-header>
      <h2 class="page-title">General Setting</h2>
    </template>
    <template #page-content>
      <div id="general-setting">
        <div class="columns is-multiline is-mobile" v-if="currentUser">
          <div class="column is-4">
            <div
              class="columns is-multiline is-mobile"
              v-if="currentUser.isAdmin"
            >
              <div class="column is-12 is-mobile pb-0">
                <p>Maximum Change Schedule (days per month)</p>
                <ValidationObserver
                  ref="observer"
                  v-slot="{ handleSubmit }"
                  v-if="!isLoadingMaxChangeSchedule"
                >
                  <form
                    @submit.prevent="handleSubmit(saveChangeSchedule)"
                    class="is-label-14-px-normal"
                    multipart
                  >
                    <div class="columns is-multiline is-mobile">
                      <div class="column is-10">
                        <ValidationProvider
                          name="maximum change schedule"
                          v-slot="{ errors }"
                          rules="required|numeric"
                        >
                          <b-input
                            placeholder="e.g. 12"
                            v-model="maxChangeSchedule"
                          ></b-input>
                          <p class="has-text-danger">{{ errors[0] }}</p>
                        </ValidationProvider>
                      </div>
                      <div class="column is-2">
                        <b-button
                          class="is-primary"
                          native-type="submit"
                          type="submit"
                          :loading="isSavingMaxChangeSchedule"
                        >
                          Save
                        </b-button>
                      </div>
                    </div>
                  </form>
                </ValidationObserver>
                <b-skeleton animated v-else />
              </div>
            </div>

            <div
              class="columns is-multiline is-mobile"
              v-if="currentUser.isAdmin"
            >
              <div class="column is-12 is-mobile pb-0">
                <p>Maximum Delay Limit</p>
                <ValidationObserver
                  ref="observer"
                  v-slot="{ handleSubmit }"
                  v-if="!isLoadingMaxDelayTime"
                >
                  <form
                    @submit.prevent="handleSubmit(saveMaxDelayTime)"
                    class="is-label-14-px-normal"
                    multipart
                  >
                    <div class="columns is-multiline is-mobile">
                      <div class="column is-10">
                        <ValidationProvider
                          name="maximum delay limit"
                          v-slot="{ errors }"
                          rules="required"
                        >
                          <b-timepicker
                            placeholder="Click to edit..."
                            hour-format="24"
                            v-model="maxDelayTime"
                            enable-seconds
                            editable
                          />
                          <p class="has-text-danger">{{ errors[0] }}</p>
                        </ValidationProvider>
                      </div>
                      <div class="column is-2">
                        <b-button
                          class="is-primary"
                          native-type="submit"
                          type="submit"
                          :loading="isSavingMaxDelayTime"
                        >
                          Save
                        </b-button>
                      </div>
                    </div>
                  </form>
                </ValidationObserver>

                <b-skeleton animated v-else />
              </div>
            </div>

            <div
              class="columns is-multiline is-mobile"
              v-if="currentUser.isAdmin"
            >
              <div class="column is-12 is-mobile pb-0">
                <p>Face Recognition Mandatory</p>
                <ValidationObserver
                  ref="observer"
                  v-slot="{ handleSubmit }"
                  v-if="!isLoadingFaceRegMandatory"
                >
                  <form
                    @submit.prevent="handleSubmit(saveFaceRegMandatory)"
                    class="is-label-14-px-normal"
                    multipart
                  >
                    <div class="columns is-multiline is-mobile">
                      <div class="column is-10">
                        <ValidationProvider
                          name="face recognition mandatory"
                          v-slot="{ errors }"
                          rules="required"
                        >
                          <b-switch
                            v-model="isFaceRegMandatory"
                            type="is-success"
                          />
                          <p class="has-text-danger">{{ errors[0] }}</p>
                        </ValidationProvider>
                      </div>
                      <div class="column is-2">
                        <b-button
                          class="is-primary"
                          native-type="submit"
                          type="submit"
                          :loading="isSavingFaceRegMandatory"
                        >
                          Save
                        </b-button>
                      </div>
                    </div>
                  </form>
                </ValidationObserver>

                <b-skeleton animated v-else />
              </div>
            </div>

            <div class="columns is-multiline is-mobile">
              <div class="column is-12 is-mobile pb-0">
                <p>Set Face Recognition</p>
                <ValidationObserver
                  ref="observer"
                  v-slot="{ handleSubmit }"
                  v-if="!isLoadingSetImageFaceReg"
                >
                  <form
                    @submit.prevent="handleSubmit(saveFaceRegMandatory)"
                    class="is-label-14-px-normal"
                    multipart
                  >
                    <div class="columns is-multiline is-mobile">
                      <div class="column is-10">
                        <p>Please take a picture to recognize your identity</p>
                      </div>
                      <div class="column is-2">
                        <b-button
                          class="is-primary"
                          type="primary"
                          @click.native="openModal"
                        >
                          {{ imageFaceReg.url ? 'Re-capture' : 'Start' }}
                        </b-button>
                      </div>
                    </div>
                  </form>
                </ValidationObserver>

                <b-skeleton animated v-else />
              </div>
            </div>
          </div>
        </div>
        <b-modal
          @close="closeModal"
          :active="isModalFaceCamOpen"
          trap-focus
          has-modal-card
        >
          <template #default="props">
            <FaceCam
              action="takePicture"
              @close="props.close"
              @submit="saveImageFaceReg"
              :submit-loading="isSavingFaceReg"
              :is-capture-finished="isCaptureFinished"
              :is-close-face-cam="isCloseFaceCam"
              show-button-capture-finished
            ></FaceCam>
          </template>
        </b-modal>
      </div>
    </template>
  </r-page>
</template>

<script>
import { convertToUTC, showToast } from '../../services/util'
import ApiService from '../../services/common/api.service'
import moment from 'moment-timezone'
import { mapGetters } from 'vuex'
import FaceCam from '@/components/FaceCam'
import apiService from '../../services/common/api.service'
export default {
  components: {
    FaceCam,
  },
  watch: {
    currentUser() {
      if (this.maxDelayTimeSettingId > 0) {
        let utcMoment = moment(this.maxDelayTimeUTC)
        this.maxDelayTime = new Date(utcMoment.format('YYYY-MM-DD HH:mm:ss'))
      }
    },
  },
  data() {
    return {
      maxDelayTime: null,
      maxDelayTimeUTC: undefined,
      maxDelayTimeSettingId: 0,
      isLoadingMaxDelayTime: false,
      isSavingMaxDelayTime: false,

      maxChangeSchedule: null,
      maxChangeScheduleSettingId: 0,
      isLoadingMaxChangeSchedule: false,
      isSavingMaxChangeSchedule: false,

      isLoadingFaceRegMandatory: false,
      isFaceRegMandatory: false,
      isSavingFaceRegMandatory: false,
      settingSavingFaceRegId: 0,

      isModalFaceCamOpen: false,
      isLoadingSetImageFaceReg: false,
      isSavingFaceReg: false,
      imageFaceReg: {
        url: null,
      },
      isCaptureFinished: false,
      isCloseFaceCam: false,
    }
  },
  computed: {
    ...mapGetters({
      currentUser: 'auth/currentUser',
    }),
  },

  methods: {
    openModal() {
      this.isCloseFaceCam = false
      this.isModalFaceCamOpen = true
    },
    dataURLtoFile(dataurl, filename) {
      // https://stackoverflow.com/questions/16968945/convert-base64-png-data-to-javascript-file-objects/16972036
      // https://stackoverflow.com/questions/35940290/how-to-convert-base64-string-to-javascript-file-object-like-as-from-file-input-f
      var arr = dataurl.split(','),
        mime = arr[0].match(/:(.*?);/)[1],
        bstr = atob(arr[1]),
        n = bstr.length,
        u8arr = new Uint8Array(n)

      while (n--) {
        u8arr[n] = bstr.charCodeAt(n)
      }

      return new File([u8arr], filename, { type: mime })
    },

    async saveImageFaceReg(image) {
      this.isSavingFaceReg = true
      try {
        let file = this.dataURLtoFile(image, 'facereg.png')

        let form = new FormData()
        form.append('image', file)

        await apiService.setHeaderMultipartFormData()
        let response = await ApiService.post(
          '/api/space-roketin/user/face-reg-file',
          form,
          true
        )

        this.imageFaceReg.url = response.data.data.faceRegFileUrl

        showToast('Image saved', 'is-success', 'is-bottom')
      } catch (err) {
        if (err?.response?.data?.message) {
          showToast(err.response.data.message, 'is-danger', 'is-bottom')
        } else {
          console.log(err)
        }
      }
      apiService.resetHeader()
      this.isSavingFaceReg = false
      this.isCaptureFinished = true
    },
    closeModal() {
      this.isCloseFaceCam = true
      this.isModalFaceCamOpen = false
      this.isCaptureFinished = false
    },
    async saveChangeSchedule() {
      this.isSavingMaxChangeSchedule = true
      try {
        if (this.maxChangeScheduleSettingId === 0) {
          let response = await ApiService.post(
            '/api/space-roketin/settings/schedule-limit',
            {
              limitRate: this.maxChangeSchedule,
            }
          )
          this.maxChangeScheduleSettingId = response.data.data.id
        } else {
          await ApiService.put(
            `/api/space-roketin/settings/schedule-limit/${this.maxChangeScheduleSettingId}`,
            {
              limitRate: this.maxChangeSchedule,
            }
          )
        }
        showToast('Saved', 'is-success', 'is-bottom')
      } catch (err) {
        if (err?.response?.data?.message) {
          showToast(err.response.data.message, 'is-danger', 'is-bottom')
        } else {
          console.log(err)
        }
      }

      this.isSavingMaxChangeSchedule = false
    },
    toUtc(dateTime) {
      return convertToUTC(dateTime)
    },
    async saveMaxDelayTime() {
      this.isSavingMaxDelayTime = true
      let utcTime = convertToUTC(this.maxDelayTime).format('HH:mm:ss')
      try {
        if (this.maxDelayTimeSettingId === 0) {
          let response = await ApiService.post(
            '/api/space-roketin/settings/company-late-hour',
            {
              time: utcTime,
            }
          )
          this.maxDelayTimeSettingId = response.data.data.id
        } else {
          await ApiService.put(
            `/api/space-roketin/settings/company-late-hour/${this.maxDelayTimeSettingId}`,
            {
              time: utcTime,
            }
          )
        }

        showToast('Saved', 'is-success', 'is-bottom')
      } catch (err) {
        if (err?.response?.data?.message) {
          showToast(err.response.data.message, 'is-danger', 'is-bottom')
        } else {
          console.log(err)
        }
      }
      this.isSavingMaxDelayTime = false
    },

    async saveFaceRegMandatory() {
      this.isSavingFaceRegMandatory = true
      try {
        if (this.settingSavingFaceRegId === 0) {
          let response = await ApiService.post(
            '/api/space-roketin/settings/face-recognition-mandatory',
            {
              isMandatory: this.isFaceRegMandatory ? 1 : 0,
            }
          )
          this.settingSavingFaceRegId = response.data.data.id
        } else {
          await ApiService.put(
            `/api/space-roketin/settings/face-recognition-mandatory/${this.settingSavingFaceRegId}`,
            {
              isMandatory: this.isFaceRegMandatory ? 1 : 0,
            }
          )
        }
        showToast('Saved', 'is-success', 'is-bottom')
      } catch (err) {
        if (err?.response?.data?.message) {
          showToast(err.response.data.message, 'is-danger', 'is-bottom')
        } else {
          console.log(err)
        }
      }

      this.isSavingFaceRegMandatory = false
    },

    async fetchMaxDelayTime() {
      this.isLoadingMaxDelayTime = true
      try {
        let response = await ApiService.get(
          '/api/space-roketin/settings/company-late-hour'
        )
        if (response.data[0]) {
          // split by ":"
          let splitted = response.data[0].time.split(':')
          let utcTime = new Date()
          utcTime.setUTCHours(splitted[0], splitted[1], splitted[2])
          this.maxDelayTimeUTC = utcTime
          this.maxDelayTimeSettingId = response.data[0].id

          let utcMoment = moment(this.maxDelayTimeUTC)
          this.maxDelayTime = new Date(utcMoment.format('YYYY-MM-DD HH:mm:ss'))
        }
      } catch (err) {
        if (err?.response?.data?.message) {
          showToast(err.response.data.message, 'is-danger', 'is-bottom')
        } else {
          console.log(err)
        }
      }
      this.isLoadingMaxDelayTime = false
    },

    async fetchMaxChangeSchedule() {
      this.isLoadingMaxChangeSchedule = true
      let response = await ApiService.get(
        '/api/space-roketin/settings/schedule-limit'
      )
      if (response.data[0]) {
        this.maxChangeSchedule = response.data[0].limitRate
        this.maxChangeScheduleSettingId = response.data[0].id
      }
      this.isLoadingMaxChangeSchedule = false
    },
    async fetchFaceRecogMandatory() {
      this.isLoadingFaceRegMandatory = true
      let response = await ApiService.get(
        '/api/space-roketin/settings/face-recognition-mandatory'
      )
      if (response.data[0]) {
        this.isFaceRegMandatory = response.data[0].isMandatory ? true : false
        this.settingSavingFaceRegId = response.data[0].id
      }
      this.isLoadingFaceRegMandatory = false
    },
    async fetchImageFaceReg() {
      this.isLoadingSetImageFaceReg = true
      try {
        let response = await ApiService.get(
          '/api/space-roketin/user/face-reg-file'
        )
        this.imageFaceReg.url = response.data.faceRegFileUrl
      } catch (err) {
        if (err?.response?.data?.message) {
          showToast(err.response.data.message, 'is-danger', 'is-bottom')
        } else {
          console.log(err)
        }
      }
      this.isLoadingSetImageFaceReg = false
    },
  },
  mounted() {
    this.fetchMaxDelayTime()
    this.fetchMaxChangeSchedule()
    this.fetchFaceRecogMandatory()
    this.fetchImageFaceReg()
  },
}
</script>

<style scoped></style>
